.LandingPage {
  padding: 8em 0;
  margin: 0 2em;
}

.LandingPage a {
  padding: 0.5em 1em;
  margin: 0 1em;
  min-width: 60px;
  color: white;
  background-color: rgb(37, 60, 143);
  border-radius: 0.5em;
  border: 2px solid rgb(99, 160, 68);
  text-decoration: none;
  text-align: center;
}

.CreateNewUser {
  margin: 0 2em;
}

.form {
  max-width: 600px;
  margin: 2em auto;
  padding: 5em 0;
  text-align: center;
  border-radius: 0.5em;
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
}

.form h3 {
  margin: 0.5em;
}

.form button {
  display: block;
  margin: 1em auto 0;
  padding: 0.5em 1em;
  min-width: 120px;
  color: white;
  background-color: rgb(37, 60, 143);
  border-radius: 0.5em;
  border: 2px solid rgb(99, 160, 68);
  cursor: pointer;
  text-align: center;
}

.sub {
  display: block;
  margin: 1em auto 0;
  padding: 0.5em 1em;
  min-width: 120px;
  color: white;
  background-color: rgb(37, 60, 143);
  border-radius: 0.5em;
  border: 2px solid rgb(99, 160, 68);
  cursor: pointer;
  text-align: center;
}

input {
  margin: 0em auto;
  text-align: center;
  padding: 0 1em;
}

.test {
  margin: 2em auto;
  padding: 2em 10em;
  border-radius: 1em;
  cursor: pointer;
  color: black;
  text-align: center;
  border: 2px solid rgb(99, 160, 68);
  background-color: rgb(99, 160, 68, 0.5);
}

button {
  display: block;
}

.testList {
  text-align: center;
}

a:link {
  text-decoration: none;
}

.content {
  text-align: center;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 600px;
  padding: 1em;
}

.answers button {
  cursor: pointer;
  color: white;
  margin: 1em;
  border-radius: 1em;
  background-color: rgb(99, 160, 68);
  -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 4px 2px rgba(109, 176, 75, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 100px;
}

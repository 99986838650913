body {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.footer {
  color: rgb(0, 0, 0);
}

.footer a {
  color: #000000 !important;
  border-bottom: 1px solid #ffffff;
}

.ending {
  display: flex;
  justify-content: space-between;
}

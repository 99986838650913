.bg {
  background-color: rgb(37, 60, 143);
}

.Guide {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 2em;
}

.appBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border-bottom: 8px solid rgb(99, 160, 68);
  min-height: 18%;
  padding-bottom: 1em;
}

.appBar img {
  width: 390px;
}

.links {
  width: 15em;
  display: flex;
  justify-content: center;
}

.links a {
  padding: 0.5em 1em;
  margin: 0 1em;
  min-width: 60px;
  color: white;
  background-color: rgb(37, 60, 143);
  border-radius: 0.5em;
  border: 2px solid rgb(99, 160, 68);
  text-decoration: none;
  text-align: center;
}

#majorSelect {
  color: white;
  justify-content: center;
  align-items: baseline;
  margin: 1.5em auto;
  padding: 0.5em 1em;
  display: flex;
  max-width: 150px;
  background-color: rgb(37, 60, 143);
  border: 2px solid rgb(99, 160, 68);
  border-radius: 0.5em;
}

#majorSelect select {
  align-items: baseline;
  text-align: center;
  background-color: rgb(37, 60, 143);
  color: white;
  border: 1px solid rgb(99, 160, 68);
  border-radius: 0.25em;
  cursor: pointer;
}

.iframe {
  width: 100%;
  padding-top: 50%;
  position: relative;
  background-color: rgb(37, 60, 143);
  border-top: 8px solid rgb(99, 160, 68);
  border-bottom: 8px solid rgb(99, 160, 68);
}

iframe {
  border-radius: 0.5em;
  border: none;
  position: absolute;
  margin: auto;
  width: 80%;
  height: 95%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.secSelect {
  color: white;
  justify-content: center;
  align-items: baseline;
  margin: 1.5em auto;
  padding: 0.5em 1em;
  display: flex;
  max-width: 150px;
  background-color: rgb(37, 60, 143);
  border: 2px solid rgb(99, 160, 68);
  border-radius: 0.5em;
}

.secSelect select {
  margin: 0 auto;
  text-align: center;
  background-color: rgb(37, 60, 143);
  color: white;
  border: none;
  cursor: pointer;
  border: 1px solid rgb(99, 160, 68);
  border-radius: 0.25em;
}

.contento {
  color: white;
  text-shadow: 0px 2px rgb(27, 50, 133);
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 1em;
  padding: 1em;
  border-radius: 0.5em;
  background-color: rgb(99, 160, 68);
  -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 4px 4px rgba(109, 176, 75, 1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.text {
  /* color: black; */
  /* max-width: 600px; */
  margin: 0 auto;
  max-width: 45%;
  height: auto;
  font-size: 18px;
  padding: 0 3em;
}

.carousel {
  margin: auto;
  position: relative;
  max-width: 700px;
  font-size: 20px;
}

.carouselInner {
  padding-top: 1em;
}

#infoPic {
  max-width: 80%;
  height: auto;
  /* width: 580px;
  height: 400px; */
  border-radius: 1em;
}

@media only screen and (max-width: 1200px) {
  .contento {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .carousel {
    border-bottom: 1px solid rgb(27, 50, 133);
  }
}

#prev,
#next {
  position: absolute;
  top: 40%;
  font-size: 3vw;
  color: white;
  background-color: rgb(27, 50, 133);
  padding: 0.5rem;
}

#prev:hover,
#next:hover {
  cursor: pointer;
  background-color: rgb(37, 60, 143, 1);
}

#prev {
  left: 0;
}

#next {
  right: 0;
}

.backToTests {
  margin: 0 auto;
}

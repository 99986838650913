.questions {
  margin: 0 2em 5em;
}

.card {
  margin: 1em auto;
  padding: 0.5em 0 1em;
  max-width: 400px;
  text-align: center;
  border-radius: 0.25em;
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
}

.card button {
  margin: 1em auto 0.5em;
}

.testList {
  margin: 0 2em;
}

input {
  /*
  This affects inputs across the entire app
  */
  padding: 0.4em;
}

.utilButton {
  display: block;
  margin: 1em auto 0;
  padding: 0.5em 1em;
  min-width: 120px;
  color: white;
  background-color: rgb(37, 60, 143);
  border-radius: 0.5em;
  border: 2px solid rgb(99, 160, 68);
  cursor: pointer;
  text-align: center;
}
